import get from 'lodash/get';

import { STATUSES } from 'constants/emailStatus';
import { SOURCE_TYPE, ENRICH_TYPE } from 'data/types/source.types';
import { ACCOUNT_EVENTS } from 'data/types/event.types';
import { isSimilar } from '../../helpers/insightHelpers';

const initialState = {
  lead: null,
  company: null,
  linkedin: null,
  email: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FIND_INSIGHT_EMAIL': {
      if (state.lead) {
        return {
          ...state,
          lead: {
            ...state.lead,
            status: STATUSES.PROCESSING,
          },
        };
      }
      return state;
    }
    case 'SET_INSIGHT_LINKEDIN_CONTACT': {
      return {
        ...state,
        linkedin: {
          ...action.data,
        },
      };
    }
    case 'SET_INSIGHT_EMAIL_CONTACT': {
      return {
        ...state,
        email: {
          ...action.data,
        },
      };
    }
    case 'GET_INSIGHT_CONTACT_BY_EMAIL': {
      if (!action?.payload) return state;
      return {
        ...state,
        email: {
          ...state.email,
          printedEmail: action.email,
        },
      };
    }
    case 'CHECK_ENRICH_CONTACT_WHEN_DELETED': {
      const { contactsIds } = action.data;

      const updatedLead = state.lead ? { ...state.lead } : null;
      const updateLinkedin = state.linkedin ? { ...state.linkedin } : null;
      const updatedEmail = state.email ? { ...state.email } : null;

      contactsIds.forEach((contacId) => {
        if (state.lead?.savedWorkspaceId === contacId) {
          updatedLead.savedWorkspaceId = null;
        }
        if (state.linkedin?.savedWorkspaceId === contacId) {
          updateLinkedin.savedWorkspaceId = null;
        }
        if (state.email?.savedWorkspaceId === contacId) {
          updatedEmail.savedWorkspaceId = null;
        }
      });

      return {
        ...state,
        lead: updatedLead,
        email: updatedEmail,
        linkedin: updateLinkedin,
      };
    }
    case 'CHECK_ENRICH_COMPANY_WHEN_DELETED': {
      const { companyIds } = action.data;

      const updatedCompany = state.company ? { ...state.company } : null;

      companyIds.forEach((companyId) => {
        if (state.company?.savedWorkspaceId === companyId) {
          updatedCompany.savedWorkspaceId = null;
        }
      });

      return {
        ...state,
        company: updatedCompany,
      };
    }
    case 'SET_INSIGHT_CONTACT': {
      return {
        ...state,
        lead: {
          ...action.data,
        },
      };
    }
    case 'SET_INSIGHT_COMPANY': {
      const { sourceType } = action;
      if (sourceType === SOURCE_TYPE.CONTACT) {
        if (state.lead) {
          return {
            ...state,
            lead: {
              ...state.lead,
              company: action.data,
            },
          };
        }
        return state;
      }
      return {
        ...state,
        company: action.data,
      };
    }
    case 'UPDATE_INSIGHTS_CONTACT_STATUS': {
      const { insightIds, isSaving } = action.data;
      const newState = { ...state };
      if (state.lead && insightIds.includes(state.lead._id)) {
        newState.lead = {
          ...newState.lead,
          isSaving,
        };
      }
      if (state.linkedin && insightIds.includes(state.linkedin._id)) {
        newState.linkedin = {
          ...newState.linkedin,
          isSaving,
        };
      }
      if (state.email && insightIds.includes(state.email._id)) {
        newState.email = {
          ...newState.email,
          isSaving,
        };
      }
      return newState;
    }
    case 'UPDATE_INSIGHTS_COMPANY_STATUS': {
      const { insightIds, isSaving } = action.data;
      if (state.company && insightIds.includes(state.company._id)) {
        return {
          ...state,
          company: {
            ...state.company,
            isSaving,
          },
        };
      }
      return state;
    }
    case 'GET_SAVED_INSIGHT_COMPANIES': {
      const { insightIds } = action;
      if (state.company && (insightIds || []).some((insightId) => state.company._id === insightId)) {
        const foundCompany = action.payload.find((company) => isSimilar(company, state.company));
        if (foundCompany) {
          return {
            ...state,
            company: {
              ...state.company,
              savedWorkspaceId: foundCompany._id,
            },
          };
        }
      }
      return state;
    }
    case 'GET_SAVED_INSIGHT_CONTACTS': {
      const { insightIds } = action;
      const newState = { ...state };
      if (state.lead && (insightIds || []).some((insightId) => state.lead._id === insightId)) {
        const foundContact = action.payload.find((contact) => isSimilar(contact, state.lead));
        if (foundContact) {
          const foundCompany = (foundContact.companyRelations || []).find((company) => isSimilar(company, state.lead.company));
          newState.lead = {
            ...newState.lead,
            savedWorkspaceId: foundContact._id,
            email: get(foundCompany, 'email.value') || state.linkedin.email,
            status: get(foundCompany, 'email.status') || state.linkedin.status,
          };
        }
      } if (state.linkedin && (insightIds || []).some((insightId) => state.linkedin._id === insightId)) {
        const foundContact = action.payload.find((contact) => isSimilar(contact, state.linkedin));
        if (foundContact) {
          const foundCompany = (foundContact.companyRelations || []).find((company) => isSimilar(company, state.linkedin.company));
          newState.linkedin = {
            ...state.linkedin,
            savedWorkspaceId: foundContact._id,
            email: get(foundCompany, 'email.value') || state.linkedin.email,
            status: get(foundCompany, 'email.status') || state.linkedin.status,
          };
        }
      } if (state.email && (insightIds || []).some((insightId) => state.email._id === insightId)) {
        const foundContact = action.payload.find((contact) => isSimilar(contact, state.email));
        if (foundContact) {
          const foundCompany = (foundContact.companyRelations || []).find((company) => isSimilar(company, state.email.company));
          newState.email = {
            ...state.email,
            savedWorkspaceId: foundContact._id,
            email: get(foundCompany, 'email.value') || state.linkedin.email,
            status: get(foundCompany, 'email.status') || state.linkedin.status,
          };
        }
      }
      return newState;
    }
    case 'SAVE_COMPANY_FROM_INSIGHTS': {
      const company = action.payload;
      if (state.company && state.company._id === company.insightId) {
        return {
          ...state,
          company: {
            ...state.company,
            isSaving: false,
            savedWorkspaceId: company._id,
          },
        };
      }
      return state;
    }
    case 'SAVE_CONTACT_FROM_INSIGHTS': {
      const contact = action.payload;
      const newState = { ...state };
      if (state.lead && state.lead._id === contact.insightId) {
        newState.lead = {
          ...state.lead,
          isSaving: false,
          savedWorkspaceId: contact._id,
        };
      }
      if (state.linkedin && state.linkedin._id === contact.insightId) {
        newState.linkedin = {
          ...state.linkedin,
          isSaving: false,
          savedWorkspaceId: contact._id,
        };
      }
      if (state.email && state.email._id === contact.insightId) {
        newState.email = {
          ...state.email,
          isSaving: false,
          savedWorkspaceId: contact._id,
        };
      }
      return newState;
    }
    case 'GET_INSIGHT_COMPANY': {
      const { sourceType } = action;
      if (sourceType === SOURCE_TYPE.CONTACT) {
        if (state.lead) {
          return {
            ...state,
            lead: {
              ...state.lead,
              company: action.payload,
            },
          };
        }
        return state;
      }
      return {
        ...state,
        company: action.payload,
      };
    }
    case ACCOUNT_EVENTS.CONTACT_EMAIL_STATUS_EVENT: {
      const newState = { ...state };
      try {
        const payloadData = action.payload ? JSON.parse(action.payload) : [];
        if (state.lead?.company || state.linkedin?.company || state.email?.company) {
          if (payloadData?.[0]?.isInsight) {
            const insight = payloadData[0];

            if (insight.contactCompanyRelation) {
              let foundCompany = payloadData.find((item) => state.lead?.company?._id === item.contactCompanyRelation);
              if (foundCompany) {
                newState.lead = {
                  ...newState.lead,
                  email: foundCompany.email,
                  status: foundCompany.status,
                };
              }
              foundCompany = payloadData.find((item) => state.linkedin?.company?._id === item.contactCompanyRelation);
              if (foundCompany) {
                newState.linkedin = {
                  ...newState.linkedin,
                  email: foundCompany.email,
                  status: foundCompany.status,
                };
              }

              foundCompany = payloadData.find((item) => state.email?.company?._id === item.contactCompanyRelation);
              if (foundCompany) {
                newState.email = {
                  ...newState.email,
                  email: foundCompany.email,
                  status: foundCompany.status,
                };
              }
            } else if (insight.contactId === state.lead?.tempId) {
              newState.lead = {
                ...newState.lead,
                email: insight.email,
                status: insight.status,
              };
            } else if (insight.contactId === state.linkedin?.tempId) {
              newState.linkedin = {
                ...newState.linkedin,
                email: insight.email,
                status: insight.status,
              };
            } else if (insight.contactId === state.email?._id) {
              newState.email = {
                ...newState.email,
                email: insight.email,
                status: insight.status,
              };
            }
          } if (state.lead?.savedWorkspaceId || state.linkedin?.savedWorkspaceId || state.email?.savedWorkspaceId) {
            const foundContact = payloadData.find((item) => state.lead?.savedWorkspaceId === item._id);
            if (foundContact?.companyRelations) {
              let foundCompany = foundContact.companyRelations.find((company) => state.lead?.company?.savedWorkspaceId === get(company, 'company._id'));
              if (foundCompany) {
                newState.lead = {
                  ...newState.lead,
                  email: get(foundCompany, 'email.value'),
                  status: get(foundCompany, 'email.status'),
                };
              }
              foundCompany = foundContact.companyRelations.find((company) => state.lead?.company?.savedWorkspaceId === get(company, 'company._id'));
              if (foundCompany) {
                newState.linkedin = {
                  ...newState.linkedin,
                  email: get(foundCompany, 'email.value'),
                  status: get(foundCompany, 'email.status'),
                };
              }
              foundCompany = foundContact.companyRelations.find((company) => state.email?.company?.savedWorkspaceId === get(company, 'company._id'));
              if (foundCompany) {
                newState.email = {
                  ...newState.email,
                  email: get(foundCompany, 'email.value'),
                  status: get(foundCompany, 'email.status'),
                };
              }
            }
          }
        }
      } catch {
        return newState;
      }
      return newState;
    }
    case 'GET_INSIGHT_CONTACTS_COUNT_BY_DOMAIN': {
      const { count = 0 } = (action.payload ?? {});
      const { sourceType } = action;

      if (state.lead?.company && sourceType === ENRICH_TYPE.CONTACT) {
        return {
          ...state,
          lead: {
            ...state.lead,
            company: {
              ...state.lead.company,
              employeeCount: state.lead._id ? count - 1 : count,
            },
          },
        };
      }
      if (state.linkedin?.company && sourceType === ENRICH_TYPE.LINKEDIN) {
        return {
          ...state,
          linkedin: {
            ...state.linkedin,
            company: {
              ...state.linkedin.company,
              employeeCount: state.linkedin._id ? count - 1 : count,
            },
          },
        };
      }
      if (state.email?.company && sourceType === ENRICH_TYPE.EMAIL) {
        return {
          ...state,
          email: {
            ...state.email,
            company: {
              ...state.email.company,
              employeeCount: state.email._id ? count - 1 : count,
            },
          },
        };
      }
      if (state.company && sourceType === ENRICH_TYPE.COMPANY) {
        return {
          ...state,
          company: {
            ...state.company,
            employeeCount: count,
          },
        };
      }
      return state;
    }
    case 'SIGN_OUT':
    case 'SWITCH_WORKSPACE': {
      return initialState;
    }
    default:
      return state;
  }
};
